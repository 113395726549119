<template>
  <div class="main-content">
    <h3 class="marginBottom20">交易详情</h3>
    <avue-form ref="form"
               v-model="form"
               v-loading="loading"
               :option="formOption">
      <template slot="businessType"><div>{{ form.businessType === 1 ? '二手房交易' : form.businessType === 2 ? '房屋租赁' : '/' }}</div></template>
      <template slot="houseCode"><div>{{ form.houseCode }}</div></template>
      <template slot="houseName"><div>{{ form.houseName }}</div></template>
      <template slot="brokerCode"><div>{{ form.brokerCode }}</div></template>
      <template slot="brokerName"><div>{{ form.brokerName }}</div></template>
      <template slot="partyAPhone"><div>{{ form.partyAPhone }}</div></template>
      <template slot="partyARole"><div>{{ form.partyARole === 1 ? '卖方' : form.partyARole === 2 ? '业主' : '/' }}</div></template>
      <template slot="partyAName"><div>{{ form.partyAName }}</div></template>
      <template slot="partyACode"><div>{{ form.partyACode }}</div></template>
      <template slot="partyBPhone"><div>{{ form.partyBPhone }}</div></template>
      <template slot="partyBRole"><div>{{ form.partyBRole === 1 ? '买方' : form.partyBRole === 2 ? '租户' : '/' }}</div></template>
      <template slot="partyBName"><div>{{ form.partyBName }}</div></template>
      <template slot="partyBCode"><div>{{ form.partyBCode }}</div></template>
      <template slot="intermediaryContractName"><div>{{ form.intermediaryContractName }}</div></template>
      <template slot="transactContractName"><div>{{ form.transactContractName }}</div></template>
      <template slot="signValidity"><div>{{ form.signValidity }} 天</div></template>
      <template slot="isOnlinePayServiceFee"><div>{{ form.isOnlinePayServiceFee === 1 ? '是' : form.isOnlinePayServiceFee === 2 ? '否' : '/' }}</div></template>
      <template slot="sellerPayServiceFee"><div>{{ form.sellerPayServiceFee }} 元</div></template>
      <template slot="buyerPayServiceFee"><div>{{ form.buyerPayServiceFee }} 元</div></template>
      <template slot="sellerPayCommission"><div>{{ form.sellerPayCommission }} %</div></template>
      <template slot="buyerPayCommission"><div>{{ form.buyerPayCommission }} %</div></template>


      <template slot="isOnlinePayCommission"><div>{{ form.isOnlinePayCommission === 1 ? '是' : form.isOnlinePayCommission === 2 ? '否' : '/' }}</div></template>
      <template slot="ownerPayServiceFee"><div>{{ form.ownerPayServiceFee }} %</div></template>
      <template slot="tenantPayServiceFee"><div>{{ form.tenantPayServiceFee }} %</div></template>
      <template slot="menuForm">
        <el-button style="padding: 9px 40px" @click="handleBack">返 回</el-button>
      </template>
    </avue-form>
  </div>
</template>

<script>
import { view } from "@/api/contract/customer"
export default {
  name: "detail",
  data(){
    return{
      loading: false,
      form: {},
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 150,
        span: 24,
        column: [
          {
            label: '业务类型',
            prop: 'businessType'
          },
          {
            label: '房源号',
            prop: 'houseCode'
          },
          {
            label: '房源名称',
            prop: 'houseName'
          },
          {
            label: '认证经纪人ID',
            prop: 'brokerCode'
          },
          {
            label: '认证经纪人',
            prop: 'brokerName'
          },
          {
            label: '甲方手机号',
            prop: 'partyAPhone'
          },
          {
            label: '甲方角色',
            prop: 'partyARole'
          },
          {
            label: '甲方姓名',
            prop: 'partyAName'
          },
          {
            label: '甲方用户ID',
            prop: 'partyACode'
          },
          {
            label: '乙方手机号',
            prop: 'partyBPhone'
          },
          {
            label: '乙方角色',
            prop: 'partyBRole'
          },
          {
            label: '乙方姓名',
            prop: 'partyBName'
          },
          {
            label: '乙方用户ID',
            prop: 'partyBCode'
          },
          {
            label: '选择居间合同',
            prop: 'intermediaryContractName'
          },
          {
            label: '选择签约合同',
            prop: 'transactContractName'
          },
          {
            label: '签约有效期',
            prop: 'signValidity'
          },
          // businessType === 1
          {
            label: '是否线上支付服务费',
            prop: 'isOnlinePayServiceFee',
            display: false
          },
          {
            label: '卖方支付服务费',
            prop: 'sellerPayServiceFee',
            labelWidth: 250,
            display: false
          },
          {
            label: '买方支付服务费',
            prop: 'buyerPayServiceFee',
            labelWidth: 250,
            display: false
          },
          {
            label: '卖方支付佣金',
            prop: 'sellerPayCommission',
            labelWidth: 250,
            display: false
          },
          {
            label: '买方支付佣金',
            prop: 'buyerPayCommission',
            labelWidth: 250,
            display: false
          },

          // businessType === 2
          {
            label: '是否线上支付佣金',
            prop: 'isOnlinePayCommission',
            display: false
          },
          {
            label: '业主支付服务费',
            prop: 'ownerPayServiceFee',
            labelWidth: 250,
            display: false
          },
          {
            label: '租户支付服务费',
            prop: 'tenantPayServiceFee',
            labelWidth: 250,
            display: false
          }
        ]
      }
    }
  },
  async mounted() {
    this.loading = true;
    await this.getView();
  },
  methods: {
    async getView(){
      const res = await view({ id: this.$route.query.id });
      // console.log(res)
      this.loading = false;
      if(res.code === 200){
        const isOnlinePayServiceFee = this.findObject(this.formOption.column, 'isOnlinePayServiceFee');
        const sellerPayServiceFee = this.findObject(this.formOption.column, 'sellerPayServiceFee');
        const buyerPayServiceFee = this.findObject(this.formOption.column, 'buyerPayServiceFee');
        const sellerPayCommission = this.findObject(this.formOption.column, 'sellerPayCommission');
        const buyerPayCommission = this.findObject(this.formOption.column, 'buyerPayCommission');

        const isOnlinePayCommission = this.findObject(this.formOption.column, 'isOnlinePayCommission');
        const ownerPayServiceFee = this.findObject(this.formOption.column, 'ownerPayServiceFee');
        const tenantPayServiceFee = this.findObject(this.formOption.column, 'tenantPayServiceFee');
        if(res.data.businessType === 1){
          isOnlinePayServiceFee.display = true;
          sellerPayServiceFee.display = true;
          buyerPayServiceFee.display = true;
          sellerPayCommission.display = true;
          buyerPayCommission.display = true;
        }else{
          isOnlinePayCommission.display = true;
          ownerPayServiceFee.display = true;
          tenantPayServiceFee.display = true;
        }
        this.form = res.data;
      }else{
        this.$router.go(-1)
      }
    },
    handleBack(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>